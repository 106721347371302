.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 3;
}

.popup .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 3;
}

.popup .popup-content {
    min-width: 600px;
    max-width: 90%;
    min-height: 200px;
    max-height: 95%;
    background-color: white;
    z-index: 4;
    overflow: auto;
    border-radius: 20px;
}