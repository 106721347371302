.statblock .statblock-content {
  font-size: 50px;
  font-weight: 600;
  line-height: 50px;
}

.statblock .statblock-content-subtitle {
  font-weight: 500;
}

.statblock .statblock-content-icon {
  position: absolute;
  right: -11px;
  bottom: -16px;
  font-size: 60px;
  line-height: 60px;
  opacity: .4;
}