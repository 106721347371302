.smart-component {
  position: relative;
}

.smart-component-spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    color: rgb(15, 23, 42);
}