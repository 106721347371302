.cluster-plot {
    position: relative;
}

.loading-cluster-plot {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #EEE;
    border-radius: 20px;
}