.pipeline .icon {
    width: 100%;
    height: auto;
    max-height: 25px;
    max-width: 25px;
    margin-right: 10px;
}

.pipeline .icon.spin {
    -webkit-animation: spin 2s linear infinite;
    -moz-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

.pipeline .stage-info {
    padding-left: 20px;
    margin-left: 12px;
}

.pipeline .stage-info .label {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
}

.pipeline .stage-info.green-border {
    border-left: 1px solid var(--green);
}

.pipeline .stage-info.red-border {
    border-left: 1px solid var(--red);
}

.pipeline .stage-info.blue-border {
    border-left: 1px solid var(--blue);
}

.pipeline .stage-info.orange-border {
    border-left: 1px solid var(--orange);
}

table.execution-table {
    
}

table.execution-table thead {
    background-color: rgba(59, 130, 246, 0.3);
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(-360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(-60deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}