.login-mask {
    width: 400px;
    box-shadow: var(--sky-shadow);
    border-radius: 15px;
}

.bg-login {
    background-color: #141e30;
}

.btn-login-adq {
    width: 100%;
    border-radius: 30px;
    background: transparent;
    border-radius: 30px;
    border: 1px solid white;
    color: white;
}

.btn-login-adq:hover {
    background-color: rgb(255, 103, 0);
    border-color: rgb(255, 103,0);
    color: white
}

.container-fluid-login {
    height: 100vh !important;
}

.input-login-adq {
    background: transparent;
    border: 1px solid white;
    border-radius: 30px;
}