.custom-overflow {
    overflow: scroll;
    max-height: 500px;
}

.custom-overflow table {
    background-color: white;
}

.custom-overflow thead {
    position: relative;
}

.custom-overflow td {
    height: 50px;
    width: 200px;
    border: 1px solid transparent;
}

.custom-overflow th {
    height: 50px;
}

.custom-overflow td:hover {
    border: 1px solid #CCC !important;
    cursor: pointer;
}