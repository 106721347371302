.btn-outline {
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  line-height: 25px;
  vertical-align: middle;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
}

.btn-outline i {
  font-size: 20px;
}

.btn-outline-1 {
  color: var(--bar-color);
  border-color: rgb(203, 213, 225);
}
  
.btn-outline-1:hover {
  background-color: rgba(203, 213, 225, .2);
  box-shadow: none;
}

.btn-outline-2 {
  background-color: #ffcf66;
  box-shadow:0 0 10px #ea843a;
}