.pallozzo {
    border-radius: 2000px;
    color: white;
    width: 35px;
    height: 35px;
    line-height: 32px;
    border: 2px solid white;
}

.pallozzo-hover {
    color: red;
    background-color: white !important;
    border: 2px solid red;
}

.overflow-table {
    overflow-x: scroll;
    width: 100%;
}

.overflow-table th, .overflow-table td {
    white-space: nowrap;
    width: 1%;
    padding: 2px 10px;
}

.rounded-navigator {
    overflow: hidden;
    border-radius: 100px;
}

.table-navigator {
    position: relative;
    min-height: 200px;
    background: yellow;
    width: 100px;
}

.navigation-block {
    min-width: 50px;
    height: 30px;
    font-size: 10px;
    background-color: white;
    border-left: 1px solid #EEE;
    border-bottom: 1px solid #EEE;
}

.navigation-block:hover {
    transform: scale(1.5);
    cursor: pointer;
    background-color: black !important;
    color: white;
}