.cluster-plot {
    position: relative;
}

.loading-cluster-plot {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #EEE;
    border-radius: 20px;
}

.paging-box {
    width: 30px;
    height: 30px;
    font-size: 12px;
    color: var(--bar-color);
    border: 1px solid var(--bar-color);
    background-color: transparent;
    border-radius: 5px;
    margin-right: 5px 
}

.paging-box.active {
    background-color: var(--bar-color);
    color: white;
    font-size: 12px;
}