.miniblock .icon {
  font-size: 20px;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  line-height: 100%;
}

.miniblock .value {
  font-size: 20px;
  line-height: 100%;
  font-weight: 500;
}

.miniblock .label {
  line-height: 100%;
  font-weight: 300;
}