.block {
  border-radius: 5px;
  background: white;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / .1), 0 1px 2px -1px rgb(0 0 0 / .1) !important;
  overflow: hidden;
  position: relative;
}

.block .block-title {
  font-weight: 500;
}

.block .item-index {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
}

.block .action-block {
  border-top: 2px solid rgba(59, 130, 246, 0.2);
}