.change-type-popup, .metadata-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);
    z-index: 100000;
}

.change-type-popup {
    z-index: 1000000;
}

.change-type-popup-box {
    border-radius: 20px;
    background-color: white;
    min-width: 500px;
    max-width: 600px;
}

.change-var-type-selector {
    border-radius: 20px;
    border: 1px solid #BBB;
    text-align: center;
    cursor: pointer;
}

.change-var-type-selector:hover {
    border: 1px solid #000;
}

.change-var-type-selector.active {
    border: 1px solid #000;
}

.variable-metadata-status {
    border-radius: 10px;
    border: 2px solid #CCC;
    color: #AAA;
    font-weight: bold;
    padding: 0px 8px;
    font-size: 10px;
    background: rgba(153, 153, 153, 0.2)
}

.variable-metadata-status.active {
    border: 2px solid var(--green);
    color: var(--green);
    background: rgba(40,199,111,.12)
}

.metadata-on-hover:hover {
    background-color: rgba(0, 0, 0, 0.034);
    cursor: pointer;
}

.metadata-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.metadata-popup-box {
    border-radius: 10px;
    background-color: white;
    width: 80%;
    height: 80%;
    position: absolute;
    top: 10%;
    left: 10%;
    padding: 30px;
}

.loading-metadata-plot {
    position: absolute;
    background: #EEE;
    border-radius: 20px;
    top: 0;
    left: 0;
}

.cluster-plot {
    position: relative;
    top: 0;
    overflow: hidden;
}

.metadata-carousel {
    width: 100%;
    overflow-x: auto;
    position: relative;
    padding-bottom: 5px;
    -ms-overflow-style: auto;  /* IE and Edge */
    scrollbar-width: auto;  /* Firefox */
}


.metadata-carousel::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 6px transparent; */
    border-radius: 3px;
    background-color: transparent;
  }
.metadata-carousel::-webkit-scrollbar {
    height: 10px;
    background-color: transparent;
    border-radius: 3px;
    display:initial;

}
.metadata-carousel::-webkit-scrollbar-thumb {
    border-radius: 3px;
    /* -webkit-box-shadow: inset 0 0 6px hsla(0, 0%, 0%, 0.589); */
    background-color: hsla(0, 0%, 0%, 0.2);
}

.arrow {
    width: 50px;
    height: 50px;
    background-color: rgba(153, 153, 153, 0.5);
    position: sticky;
    right: 0;
    top: 40%;
    overflow: hidden;
    border-radius: 5px;
    font-size: 25px;
    line-height: 50px;
    text-align: center;
}