@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://cdn-uicons.flaticon.com/uicons-regular-rounded/css/uicons-regular-rounded.css');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");
@import url('https://cdn-uicons.flaticon.com/uicons-bold-rounded/css/uicons-bold-rounded.css');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Sora:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Sora:wght@100;200;300;400;500;600;700;800&display=swap');

@font-face {
  font-family: title;
  src: url("fonts/FS\ Elliot\ Pro-Heavy.otf");
}

:root {
  --color1: rgba(0, 171, 233, 1);
  --color2: rgba(37, 217, 248, 0.7);
  --color3: #1FC992;
  --bar-color: rgb(15, 23, 42);
  --gradient: linear-gradient(45deg, var(--sky1) 0%, var(--sky2) 100%);
  --shadow: 0 0 15px 0 rgb(34 41 47 / 5%);
  --menu-bar-width: 80px;
  --radius: 4px;
  --orange: rgb(245, 158, 11);
  --green: rgb(40, 200, 112);
  --red: #ef4343;
  --blue: rgb(59, 130, 246);
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  border: none;
  width: 100%;
  min-height: 100%;
  background-color: rgb(241, 245, 249);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: normal;
}

a,
a:hover,
a:focus {
  text-decoration: none;
  color: inherit;
}

.text-xl {
  font-size: 22px;
}

.text-lg {
  font-size: 18px;
}

.text-md {
  font-size: 14px;
}

.text-sm {
  font-size: 12px;
}

.text-bold {
  font-weight: 500;
}

.text-transaparent {
  color: transparent;
}

.blue {
  color: var(--blue);
}

.green {
  color: var(--green);
}

.orange {
  color: var(--orange);
}

.red {
  color: var(--red);
}

.back-dark {
  background: var(--bar-color);
  color: white;
}

.back-white {
  background: white;
}

.back-grey {
  background: rgba(0,0,0,0.1) !important;
}

.back-orange {
  background-color: rgba(245, 158, 11, 0.12) !important;
}

.back-green {
  background-color: rgba(40, 199, 111, .12) !important;
}

.back-blue {
  background-color: rgba(59, 130, 246, .12) !important;
}

.back-red {
  background-color: rgba(239, 68, 68, .12) !important;
}

.border-highlighted {
  border: 2px solid var(--blue);
}

.pill-blue {
  background-color: rgba(59, 130, 246, 0.3);
  font-weight: 700;
  color: var(--blue);
  border-radius: 5px;
  font-size: 12px;
  text-transform: uppercase;
  padding: 2px 10px;
}

.pill-blue-dark {
  background-color: rgb(15, 23, 42);
  font-weight: 700;
  color: white;
  border-radius: 5px;
  font-size: 12px;
  text-transform: uppercase;
  padding: 2px 10px;
}

.pill-green {
  background-color: rgba(40, 200, 112, 0.3);
  font-weight: 700;
  color: var(--green);
  border-radius: 5px;
  font-size: 12px;
  text-transform: uppercase;
  padding: 2px 10px;
}

.pill-orange {
  background-color: rgba(245, 158, 11, 0.3);
  font-weight: 700;
  color: var(--orange);
  border-radius: 5px;
  font-size: 12px;
  text-transform: uppercase;
  padding: 2px 10px;
}

.pill-red {
  background-color: rgba(239, 68, 68, 0.3);
  font-weight: 700;
  color: var(--red);
  border-radius: 5px;
  font-size: 12px;
  text-transform: uppercase;
  padding: 2px 10px;
}

.pill-rounded {
  background-color: rgba(0,0,0,0.1);
  border-radius: 200px;
  height: 50px;
  width: 50px;
  font-size: 30px;
}

.pill-rounded > img {
  width: 60%;
  height: 60%;
}

.on-hover {
  cursor: pointer;
}

.on-hover-denied {
  cursor: not-allowed;
}

.on-hover-bg-blue:hover {
  background-color: rgba(59, 130, 246, .12) !important;
  border-color: rgba(59, 130, 246, .12) !important;
}

.on-hover-bg-red:hover {
  background-color: rgba(239, 68, 68, 0.3) !important;
  border-color: var(--red) !important;
}

.on-hover-bg-green:hover {
  background-color: rgba(40, 199, 111, .12) !important;
  border-color: var(--green) !important;
}

.on-hover-bg-orange:hover {
  background-color: rgba(245, 158, 11, 0.12) !important;
  border-color: var(--orange) !important;
}

.border-green {
  border-color: var(--green) !important;
}

.border-orange {
  border-color: var(--orange) !important;
}

.border-red {
  border-color: var(--red) !important;
}

.overlay-message {
  position: fixed;
  width: 400px;
  z-index: 100000000;
  top: 20px;
  right: 20px;
  border-radius: 5px;
}

.overlay-message .round-time-bar div {
  height: 3px;
  animation: roundtime calc(5 * 1s) linear forwards;
  transform-origin: left center;
}

.overlay-message.success .round-time-bar div {
  background: linear-gradient(to bottom, var(--green), var(--green));
}

.overlay-message.success .title {
  color: var(--green);
}

.overlay-message.error .round-time-bar div {
  background: linear-gradient(to bottom, var(--red), var(--red));
}

.overlay-message.error .title {
  color: var(--red);
}

.rounded-md {
  border-radius: 5px;
}

.user-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 700;
  color: var(--blue);
}

@keyframes roundtime {
  to {
    /* More performant than animating `width` */
    transform: scaleX(0);
  }
}