.user-card {
    background-color: #efefef;
    border-radius: 20px;
    border: 1px solid #efefef;
}

.user-card:hover {
    border: 1px solid #ccc;
    cursor: pointer;
}

.user-card .user-icon {
    max-height: 80px;
}

.user-card.user-card-active {
    border-color: var(--color3);
}