.main-block {
    width: 100%;
    top: 60px;
    left: 0;
    right: 0;
    overflow: hidden;
    color: rgb(30, 41, 59);
    min-height: calc(100% - 60px);
}

.main-block .page-title {
    font-size: 30px;
    font-family: "title";
    color: var(--bar-color);
}

.menu-bar {
    height: 60px;
    background-color: var(--bar-color);
    box-shadow: var(--shadow);
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    color: white;
    overflow: hidden;
    position: relative;
}

.menu-bar .block-logo {
    width: auto;
}

.menu-bar .block-logo img#logo {
    width: auto;
    height: 40px;
    margin-top: 10px;
}

.menu-bar .menu-items {
    position: relative;
    overflow: hidden;
}

.menu-bar .menu-item {
    position: relative;
    height: 40px;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
    width: 150px;
}

.menu-bar .menu-item:hover,
.menu-bar .menu-item .active,
.menu-bar .menu-item.active {
    background: rgba(255, 255, 255, 0.12);
    /* box-shadow: 0 0 10px 1px var(--color2);
    -webkit-box-shadow: 0 0 10px 1px var(--color2); */
    color: white;
    border-radius: var(--radius);
}

.block-user .block-user-content {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    position: relative;
}

.menu-bar .block-user img {
    width: 40px;
    height: 40px;
}