.popup {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 1;
}

.popup .content {
    min-width: 500px;
    max-width: 80%;
    background-color: white;
    border-radius: 10px;
    position: relative;
}

.popup .content .close {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 20px;
    cursor: pointer;
}

.popup .content img{
    height: 30px;
    width: 30px;
    border-radius: 100%;
}



