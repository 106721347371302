.log-box {
    border-radius: 100px;
    border: 1px solid rgb(203, 213, 225);
    cursor: pointer;
}

.log-box:hover, .log-box.active {
    border: 1px solid black;
}

.log-window-wrapper {
    overflow-y: hidden;
}

.log-window {
    height: 100%;
    border: 1px solid rgb(203, 213, 225);
}