#user-management #profile-pic {
    height: 200px;
    width: 200px;
    border-radius: 200px;
}

#user-management #profile-pic img {
    height: 100%;
}

#user-management .user-management-popup {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

#user-management .user-management-popup .user-management-popup-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    width: 500px;
    overflow: auto;
}